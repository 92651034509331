.root {
  height: 48px;
  background: #eeeeee;
  border-radius: 24px;
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.loading {
  background: #1356a8;
  display: block;
  width: 100%;
  transform: translateX(-50%);
  height: 100%;
  transition: 1s ease-in-out all;
}
