.root {
  height: 48px;
  background-color: var(--black);
  text-align: left;
  display: flex;
  align-items: center;
  padding: 0 24px;
  position: relative;
  overflow-y: hidden;
}

.logo {
  cursor: pointer;
}

.progressBar {
  content: '';
  display: block;
  background: #1356a8;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: 1s ease-in-out all;
}

.content {
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 4fr;
  width: 100%;
}

.home {
  background: transparent;
  border: none;
  color: var(--white);
  cursor: pointer;
  text-align: left;
}

@media screen and (max-width: 600px) {
  .content {
    display: flex;
    justify-content: space-between;
  }
}
