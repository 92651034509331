.root {
  font-style: normal;
  font-weight: normal;

  font-feature-settings: 'liga' off;
}

.standard {
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
}

.big {
  font-size: 24px;
  line-height: 36px;
}

.boldBlue {
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  color: var(--blue);
}

@media screen and (max-width: 600px) {
  .big {
    font-size: 20px;
    line-height: 1.6;
  }
}
