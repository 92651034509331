.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media screen and (max-width: 600px) {
  .root {
    height: 100vh;
  }
}
