.root {
  /* min-width: 5px; */
  /* min-height: 5px; */
  border: 1px solid red;
  display: block;
  background: transparent;
  position: relative;
  z-index: 10;
  position: absolute;
}

.active {
  border: 2px solid blue;
}
