body {
  margin: 0;
  padding: 0;
  font-family: 'TT Norms', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --black: #111111;
  --accent-color: var(--blue);
  --light-gray: #eeeeee;
  --dark-gray: #aaaaaa;
  --gray: #dddddd;
  --disabled: #aaaaaa;
  --dark-blue: #002a5b;
  --blue: #1356a8;
  --green: #2ac178;
  --red: #c60c31;
  --black: #111111;
  --light-black: #444444;
  --white: #fafafa;
  color: var(--black);
  background-color: var(--white);
}

@font-face {
  font-family: 'TT Norms';
  src: url('/TTNorms/ttnorms-regular-webfont.woff2') format('woff2'),
    url('/TTNorms/ttnorms-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms';
  src: url('/TTNorms/ttnorms-bold-webfont.woff2') format('woff2'),
    url('/TTNorms/ttnorms-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Norms';
  src: url('/TTNorms/ttnorms-medium-webfont.woff2') format('woff2'),
    url('/TTNorms/ttnorms-medium-webfont.ttf') format('ttf'),
    url('/TTNorms/ttnorms-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
