.root {
  position: relative;
  height: 100vh;
  box-sizing: border-box;
  padding-top: 48px;
}
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .root {
    min-height: 100%;
    height: auto;
  }

  .footer {
    display: none;
  }
}
