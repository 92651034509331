.root {
  padding: 36px 24px;
}

.root a {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: inline-block;
  margin-right: 24px;
  text-decoration: none;
  color: #111;
}

@media screen and (max-width: 600px) {
  .root {
    padding: 36px 24px;
  }
  .root a {
    display: block;
    text-align: center;
    margin-bottom: 24px;
  }
}
