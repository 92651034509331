.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
}

@media screen and (max-width: 600px) {
  .root {
    height: 100vh;
  }
}
