.tokenSection {
  background-color: #eeeeee;
  padding: 37px 0 48px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 96px;
}

.tokenForm {
  max-width: 744px;
  margin: 0 auto;
  width: 100%;
}
