.root {
  height: 12px;
  width: 12px;
  border: 2px solid #111111;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  height: 6px;
  width: 6px;
  background-color: #111111;
}
