.root {
  height: 48px;
  width: 48px;
  background: var(--light-black);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
