.input {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  max-width: 100%;
  text-overflow: ellipsis;
  width: 100%;
}

.root {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-left: 100px;
}

.controls {
  width: 80px;
  position: relative;
}

.icon {
  padding-top: 0 !important;
}
