.root {
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  border: none;
  color: var(--black);
  background: var(--white);
  border-radius: 12px;
  cursor: pointer;
  display: inline-block;
  transition: 0.3s ease all;
  outline: none;
}

.sizeM {
  padding: 14px 24px;
  border-radius: 24px;
  font-size: 16px;
}

.sizeS {
  padding: 9px 24px;
  font-size: 12px;
  border-radius: 16px;
  line-height: 14px;
}

.white {
  background: var(--white);
  color: var(--black);
}

.blue {
  background: var(--blue);
  color: var(--white);
}

.blue:hover {
  background: var(--dark-blue);
}

.white:hover {
  background: var(--gray);
}

.black:hover {
  background: var(--white);
  color: var(--black);
}

.black {
  background: var(--black);
  color: var(--white);
}

.blackOnWhite:hover {
  background: var(--light-black);
  color: var(--white);
}

.blackOnWhite {
  background: var(--black);
  color: var(--white);
}

.blue {
  background: var(--blue);
}

.green {
  background: var(--green);
  color: var(--white);
}

.blue:hover {
  background: var(--dark-blue);
}

.disabled {
  background: var(--disabled);
  cursor: default;
}

.disabled:hover {
  background: var(--disabled);
}
